import React, { FC, HTMLProps } from "react";
import cx from "classnames";
import { Spinner } from "../spinner";

interface Props extends HTMLProps<HTMLButtonElement> {
  variant?: "default" | "text" | "success" | "primary" | "danger";
  type?: "button" | "submit" | "reset";
  loading?: boolean;
}

const Button: FC<Props> = ({
  onClick,
  className,
  disabled = false,
  variant = "default",
  children,
  loading,
  ...props
}) => {
  const primaryVariant: string = cx(
    "bg-primary hover:bg-primary-hover disabled:hover:bg-primary text-grey-light"
  );

  const defaultVariant: string = cx(
    "bg-gray-200 hover:bg-gray-300 disabled:hover:bg-gray-200 text-primary"
  );

  const textVariant: string = cx(
    "bg-transparent text-blue-600 text-grey-200 disabled:hover:text-gray-800"
  );

  const successVariant: string = cx(
    "bg-green-600 hover:bg-green-500 disabled:hover:bg-green-300 text-white"
  );

  const dangerVariant: string = cx(
    "bg-red-600 hover:bg-red-500 disabled:hover:bg-red-300 text-white"
  );

  const buttonClassName: string = cx(
    "relative select-none",
    { "py-3 px-6": variant !== "text" },
    "disabled:opacity-50 disabled:cursor-not-allowed",
    { [defaultVariant]: variant === "default" },
    { [primaryVariant]: variant === "primary" },
    { [textVariant]: variant === "text" },
    { [successVariant]: variant === "success" },
    { [dangerVariant]: variant === "danger" },
    className
  );

  return (
    <button
      onClick={onClick}
      className={buttonClassName}
      disabled={disabled}
      {...props}
    >
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center w-full">
          <Spinner />
        </div>
      )}
      <span className={loading ? "invisible" : ""}>{children}</span>
    </button>
  );
};

export { Button };
