import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from "react";
import {
  getInitialContext,
  getInitialState,
  identityFunction,
  normaliseCharacters,
} from "./helpers";
import { InitialContext, LearntCharacter } from "./types";
import { reducer } from "./reducer";
import { Glyph, Word } from "@/characters";
import { Filters } from "@/types";

export const GlobalContext = createContext<InitialContext>(getInitialContext());

interface Props {
  children: ReactNode;
}

const initialState = getInitialState();

export const GlobalContextProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const removeLearntCharacter = (character: LearntCharacter): void => {
    dispatch({ type: "REMOVE_LEARNT_CHARACTER", payload: character });
  };

  const setAppData = (data: any): void => {
    const { userProgress, characters, words, user } = data;
    dispatch({
      type: "SET_APP_DATA",
      payload: {
        characters: normaliseCharacters(characters),
        userProgress,
        user,
        words,
      },
    });
  };

  const updateUserNotificationStatus = (status: boolean): void => {
    dispatch({ type: "UPDATE_USER_NOTIFICATION_STATUS", payload: status });
  };

  const addLearntCharacter = (character: LearntCharacter): void => {
    dispatch({ type: "ADD_LEARNT_CHARACTER", payload: character });
  };

  const setNextRevisionIndex = (): void => {
    dispatch({ type: "SET_NEXT_REVISION_INDEX" });
  };

  const resetState = (): void => {
    dispatch({ type: "RESET_STATE" });
  };

  const getCharacters = () => {
    return state.characters;
  };

  const getCharacterList = (
    filters: Filters<Glyph> = [identityFunction]
  ): Glyph[] => {
    return filters.reduce(
      (acc, fn): Glyph[] => fn(acc),
      Object.values(getCharacters())
    );
  };

  const getLearntCharacters = () => {
    return state.learntCharacters;
  };

  const getLearntCharactersIds = () => {
    return state.learntCharacters.map(
      (learntChar) => getCharacters()[learntChar]?.id as number
    );
  };

  const getWords = (filters: Filters<Word> = [identityFunction]): Word[] => {
    return filters.reduce((acc, fn): Word[] => fn(acc), state.words);
  };

  const getRevisionIndex = () => {
    return state.revisionIndex;
  };

  return (
    <GlobalContext.Provider
      value={{
        isLoggedIn: state.isLoggedIn,
        user: state.user,
        getCharacters,
        getCharacterList,
        getLearntCharacters,
        getLearntCharactersIds,
        getWords,
        getRevisionIndex,
        removeLearntCharacter,
        addLearntCharacter,
        setAppData,
        setNextRevisionIndex,
        resetState,
        updateUserNotificationStatus,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
