import shuffle from "lodash.shuffle";
import { Action, State } from "./types";
import { getInitialState } from "./helpers";

export const reducer = (state: State, action: Action): State => {
  const removeItemFromList = (item: string) => item !== action.payload;
  switch (action.type) {
    case "SET_APP_DATA": {
      const { userProgress, characters, words, user } = action.payload;
      return {
        ...state,
        characters,
        learntCharacters: userProgress,
        words,
        user,
        isLoggedIn: true,
      };
    }
    case "SET_NEXT_REVISION_INDEX": {
      const { learntCharacters, revisionIndex } = state;
      const hasCharactersToRevise: boolean = learntCharacters.length > 0;
      const hasRevisedAllCharacters: boolean =
        revisionIndex === learntCharacters.length - 1;
      if (hasCharactersToRevise && hasRevisedAllCharacters) {
        return {
          ...state,
          revisionIndex: 0,
          learntCharacters: shuffle(learntCharacters),
        };
      }
      return {
        ...state,
        revisionIndex: revisionIndex + 1,
      };
    }
    case "ADD_LEARNT_CHARACTER": {
      const { learntCharacters } = state;
      const { payload } = action;

      return {
        ...state,
        learntCharacters: [
          ...learntCharacters.filter(removeItemFromList),
          payload,
        ],
      };
    }
    case "REMOVE_LEARNT_CHARACTER": {
      const { learntCharacters } = state;

      return {
        ...state,
        learntCharacters: [...learntCharacters.filter(removeItemFromList)],
        // need to reset the revision if the list of known characters changes
        revisionIndex: 0,
      };
    }
    case "UPDATE_USER_NOTIFICATION_STATUS": {
      const status = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          email: state.user?.email || "",
          tier: state.user?.tier || "free",
          isRegisteredForNotifications: status,
        },
      };
    }
    case "RESET_STATE":
      return getInitialState();
  }
};
