import { Glyph } from "@/characters";
import { NormalisedCharacters, State } from "./types";

const INITIAL_REVISION_INDEX: number = 0;
const INITIAL_CHARACTERS: State["characters"] = {};
const INITIAL_LEARNT_CHARACTERS: State["learntCharacters"] = [];
const INITIAL_WORDS: State["words"] = [];

export const getInitialState = (): State => ({
  isLoggedIn: false,
  user: null,
  characters: INITIAL_CHARACTERS,
  revisionIndex: INITIAL_REVISION_INDEX,
  learntCharacters: INITIAL_LEARNT_CHARACTERS,
  words: INITIAL_WORDS,
});

export const getInitialContext = () => ({
  ...getInitialState(),
  getCharacters: () => INITIAL_CHARACTERS,
  getCharacterList: () => [],
  getLearntCharacters: () => INITIAL_LEARNT_CHARACTERS,
  getLearntCharactersIds: () => [],
  getWords: () => INITIAL_WORDS,
  getRevisionIndex: () => INITIAL_REVISION_INDEX,
  setAppData: () => {},
  setNextRevisionIndex: () => {},
  addLearntCharacter: () => {},
  removeLearntCharacter: () => {},
  resetState: () => {},
  updateUserNotificationStatus: () => {},
});

export const normaliseCharacters = (
  characters: Glyph[]
): NormalisedCharacters => {
  if (characters) {
    return characters.reduce(
      (accumulatedCharacters: NormalisedCharacters, character: Glyph) => {
        accumulatedCharacters[character.slug] = character;
        return accumulatedCharacters;
      },
      {}
    );
  }
  return {};
};

export const identityFunction = (x: any) => x;
export const identitySort = (x: any, y: any): number => 1;
