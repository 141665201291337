import React, { FC, ReactNode, useEffect } from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
const CLIENT_KEY = process.env.GATSBY_GROWTHBOOK_CLIENT_KEY;

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== "production",
  subscribeToChanges: true,
});

interface Props {
  children: ReactNode;
}

const FeatureFlagContextProvider: FC<Props> = ({ children }) => {
  useEffect(() => {
    growthbook.loadFeatures();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export { FeatureFlagContextProvider };
