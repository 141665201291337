import "@fontsource/sarabun/thai-400.css";
import "@fontsource/prompt/thai-400.css";
import "@fontsource/amatic-sc/700.css";
import "./src/styles/global.css";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import React from "react";
import { GlobalContextProvider, FeatureFlagContextProvider } from "@/contexts";
import LogRocket from "logrocket";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Button } from "@/components/button";

const LOG_ROCKET_ID = process.env.GATSBY_LOG_ROCKET_ID;
const RECAPTCHA_TEST_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY || RECAPTCHA_TEST_KEY;

const initiateAnalytics = () => {
  LOG_ROCKET_ID && LogRocket.init(LOG_ROCKET_ID);
};

export const wrapRootElement = ({ element }) => {
  window.history.scrollRestoration = "manual";

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_KEY}
      scriptProps={{ async: true }}
    >
      <GlobalContextProvider>{element}</GlobalContextProvider>
    </GoogleReCaptchaProvider>
  );
};

export const wrapPageElement = ({ element }) => {
  return (
    <FeatureFlagContextProvider>
      <CookieConsent
        onAccept={initiateAnalytics}
        location="bottom"
        buttonText="Accept"
        cookieName="cookie-consent"
        style={{
          borderTop: "black solid 4px",
          background: "white",
          color: "black",
          alignItems: "center",
        }}
        containerClasses="flex py-2 pb-12 flex-col sm:flex-row sm:pb-6 border-solid border"
        contentClasses="flex w-full items-center justify-center sm:justify-end m-0 sm:pl-24 md:pl-48"
        contentStyle={{ flex: "1", margin: 0 }}
        declineButtonText="Decline"
        enableDeclineButton={true}
        declineButtonClasses="bg-red-600 text-white flex-1 sm:w-48 hover:bg-red-700"
        buttonWrapperClasses="flex flex-1 justify-center w-full gap-4 px-6 sm:flex-col sm:w-48"
        buttonClasses="bg-green-600 text-white flex-1 sm:w-48"
        expires={150}
        ButtonComponent={(props) => {
          const { onClick, children, className } = props;
          return (
            <Button className={className} onClick={onClick} variant="primary">
              {children}
            </Button>
          );
        }}
      >
        <div className="items-end mx-6 mb-4 sm:my-0">
          <div className="max-w-sm">
            <p className="pb-2 font-bold sm:text-lg">
              Cookies on Learntoreadthai.co
            </p>
            <p className="text-sm sm:text-base">
              We use some essential cookies to make this service work. We’d like
              to set additional cookies so we can understand how people use the
              service and make improvements.
            </p>
          </div>
        </div>
      </CookieConsent>
      {element}
    </FeatureFlagContextProvider>
  );
};

export const onClientEntry = () => {
  const allowCookies = getCookieConsentValue("cookie-consent");
  if (allowCookies) {
    initiateAnalytics();
  }
};

export const registerServiceWorker = () => true;
