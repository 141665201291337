import React, { FC } from "react";
import cx from "classnames";

interface Props {
  size?: "sm" | "md" | "lg" | "content";
  variant?: "default" | "transparent";
}

const Spinner: FC<Props> = ({ size = "content", variant = "default" }) => {
  const spinnerClassName: string = cx(
    { "w-24 h-24 border-[16px]": size === "lg" },
    { "w-16 h-16 border-[10px]": size === "md" },
    { "w-10 h-10 border-[3px]": size === "sm" },
    { "h-[1.5rem] w-[1.5rem] border-[3px]": size === "content" },
    "border-solid rounded-full  animate-spin block",
    { "border-tertiary border-t-primary": variant === "default" },
    {
      "border-[rgba(0,0,0,.05)] border-t-[rgba(0,0,0,.3)]":
        variant === "transparent",
    }
  );

  return <span className={spinnerClassName} />;
};

export { Spinner };
